import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useMemo, useState } from 'react';
import TableGroupObject from './Table';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import useObjectManagement from 'src/services/useObjectManagement';
import {
  reducerUpdateGroupObject,
  reducerUpdateObjectManagementGroupSelectedById
} from 'src/features/objectManagement';
import { IGroupObject, IObject } from 'src/models/objectManagement';
import { useLocation } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import ModalManageObjectByGroup from '../ModalManageObjectByGroup';
import DownloadIcon from '@mui/icons-material/Download';
import useDownload from 'src/services/useDownload';
import { fillParamAdvanceSearchEmpty } from 'src/utils/fillParamAdvanceSearchEmpth';
import { useFiltersByName } from 'src/hooks/useFiltersByName';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAlert } from 'src/hooks/useAlert';

const GroupObject = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const page = pathname.split('/')[2];
  const dispatch = useAppDispatch();
  const { downloadCsv } = useDownload();
  const { groupObject, groupSelectedById, objectList } = useAppSelector(
    (state) => state.objectManagement
  );
  const projectId = useAppSelector((state) => state.users.project.id);
  const { objectManagementAddGroup, resObjectManagementAddGroup } =
    useObjectManagement();
  const [open, setOpen] = useState(false);
  const [groupName, setGroupName] = useState<string>('');
  const [editingGroup, setEditingGroup] = useState<boolean>(false);

  const { handleClickAlert } = useAlert();
  const autoRefreshInterval = useAppSelector(
    (state) => state.autoRefreshMetric.selectedAutoRefreshInterval
  );

  const handleClose = () => setOpen(false);

  const handleOpen = () => {
    if (autoRefreshInterval > 0) {
      handleClickAlert({
        vertical: 'top',
        horizontal: 'center',
        severity: 'warning',
        message: 'You can manage objects only when auto refresh is off'
      });
      return;
    }
    setOpen(true);
  };

  const [openAddGroup, setOpenAddGroup] = React.useState(false);
  const handleClickOpenAddGroup = () => {
    setOpenAddGroup(true);
  };
  const handleCloseAddGroup = () => {
    setOpenAddGroup(false);
  };

  const handleCreateGroup = (e) => {
    e.preventDefault();
    objectManagementAddGroup({
      variables: {
        projectId,
        objectList: [],
        templateName: 'content',
        groupName
      }
    }).then((response) => {
      const group: IGroupObject =
        response.data?.objectManagement_addObjectGroup;
      dispatch(
        reducerUpdateGroupObject({
          ...groupObject,
          data: [...groupObject.data, group]
        })
      );
      dispatch(reducerUpdateObjectManagementGroupSelectedById(group.id));
      setOpenAddGroup(false);
      setEditingGroup(true);
      handleClose();
    });
  };

  const objectListByPage = useMemo((): Array<IObject> => {
    switch (page) {
      case 'content':
        return objectList.data.filter(
          (object) => object.streamType === 'account'
        );
      case 'community':
        return objectList.data.filter(
          (object) => object.streamType === 'account'
        );
      default:
        return objectList.data;
    }
  }, [page, objectList]);

  const { filteredList, handleFilter } = useFiltersByName<IGroupObject>(
    groupObject.data
  );
  // =--------------------------------------------------------------------------------
  const { dateRangeFormatted, objectIdList } = useAppSelector(
    (state) => state.analyticArguments
  );
  const advanceSearchSocialMedia = useAppSelector(
    (state) => state.storeAdvanceSearchSocialMedia
  );

  const fillAdvanceSearchSocialMedia = useMemo(() => {
    return fillParamAdvanceSearchEmpty(advanceSearchSocialMedia);
  }, [advanceSearchSocialMedia]);

  const objectListByObjectTypeActive = useMemo(() => {
    const dataList = objectList.data.filter((object) => {
      if (object.streamType !== 'account') return false;
      return objectIdList.find((id) => id === object.id);
    });
    return dataList.map((object) => {
      return object.id;
    });
  }, [objectIdList, page]);

  const payloads = {
    columnName: 'engagementRate',
    projectId,
    timezone: 7,
    start: dateRangeFormatted.start,
    end: dateRangeFormatted.end,
    granularity: {
      unit: 'day',
      value: 1
    },
    objectList:
      page === 'content' || page === 'community'
        ? objectListByObjectTypeActive
        : objectIdList,
    ...fillAdvanceSearchSocialMedia
  };

  const [anchorElDownload, setAnchorElDownload] =
    useState<HTMLButtonElement | null>(null);

  const handleClickDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElDownload(event.currentTarget);
  };

  const handleCloseDownload = () => {
    setAnchorElDownload(null);
  };

  const handleDownload = (templateName: string) => {
    const tempTemplateName =
      templateName === 'social_media' ? 'streamPanel' : templateName;
    handleCloseDownload();
    const payloadCsv = {
      ...payloads,
      templateName: tempTemplateName,
      email: ''
    };

    downloadCsv({
      variables: payloadCsv
    });
  };

  const openDownload = Boolean(anchorElDownload);
  const id = openDownload ? 'simple-popover' : undefined;

  const isShowDownloadWordcloud =
    page !== 'social_media' && page !== 'people_panel' && page !== 'sna';

  const isStreamPanel = page === 'social_media';

  console.log(page);

  return (
    <Stack
      direction="row"
      alignItems={'center'}
      justifyContent={'space-between'}
      mt={2}
      gap={2}
    >
      <FormControl size="small" sx={{ flexBasis: '370px' }}>
        <Select
          name="group"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={groupSelectedById}
          defaultValue={groupSelectedById}
        >
          <MenuItem
            value={'_default'}
            key={'_default'}
            sx={{ display: 'none' }}
          >
            All Social Profile
          </MenuItem>
          {groupObject.data.map((group) => (
            <MenuItem key={group.id} value={group.id} sx={{ display: 'none' }}>
              {group.displayName}
            </MenuItem>
          ))}

          <Box sx={{ p: theme.spacing(2) }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">Group</Typography>
              <Button
                size="small"
                variant="outlined"
                onClick={(event) => {
                  handleClickOpenAddGroup();
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                New group +
              </Button>
            </Stack>
            <Input
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              fullWidth
              placeholder="Search Source"
              onChange={(event) => {
                handleFilter(event.target.value);
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            />
            <Box sx={{ mt: theme.spacing(1) }}>
              <TableGroupObject
                groupObjects={[
                  {
                    displayName: 'All Social Profile',
                    id: '_default',
                    name: 'All Social Profile',
                    objectList: objectListByPage
                  },
                  ...filteredList
                ]}
                loading={groupObject.loading}
                groupSelectedById={groupSelectedById}
                onClick={handleClose}
              />
            </Box>
          </Box>
        </Select>
      </FormControl>
      {page !== 'overview' &&
      page !== 'people_panel' &&
      page !== 'clustering' ? (
        <Button
          size="small"
          variant="outlined"
          color="primary"
          startIcon={<DownloadIcon />}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{
            minWidth: theme.spacing(6),
            height: 36,
            backgroundColor: '#FBFBFB',
            borderRadius: theme.spacing(1),
            flexBasis: '1'
          }}
          onClick={handleClickDownload}
        >
          Download Report
        </Button>
      ) : (
        <></>
      )}

      <Dialog
        onClose={handleCloseAddGroup}
        open={openAddGroup}
        fullWidth
        maxWidth={'sm'}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseAddGroup}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            m: theme.spacing(3, 3, 1),
            textAlign: 'center'
          }}
        >
          <Typography variant="h3">Create new group</Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            m: theme.spacing(1, 3)
          }}
        >
          <form onSubmit={handleCreateGroup}>
            <FormControl fullWidth size="medium">
              <FormLabel>Group name</FormLabel>
              <OutlinedInput
                name="objectName"
                onChange={(e) => setGroupName(e.target.value)}
              />
            </FormControl>
            <Box display="flex" justifyContent="center" mt={theme.spacing(4)}>
              <Button
                variant="contained"
                type="submit"
                size="large"
                sx={{
                  background: theme.palette.primary.dark
                }}
                disabled={resObjectManagementAddGroup.loading}
              >
                Create New
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <ModalManageObjectByGroup
        title="Group"
        isHidden={true}
        onCloseEdit={() => setEditingGroup(false)}
        isEditing={editingGroup}
      />
      <Popover
        id={id}
        open={openDownload}
        anchorEl={anchorElDownload}
        onClose={handleCloseDownload}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List>
          <ListItemButton onClick={() => handleDownload(page)}>
            <ListItemText
              primary={`CSV - ${page === 'sna' ? 'SNA' : 'All Metrik'} `}
            />
          </ListItemButton>
          {isStreamPanel ? (
            <ListItemButton onClick={() => handleDownload('sna')}>
              <ListItemText primary="CSV - SNA" />
            </ListItemButton>
          ) : null}
          {isShowDownloadWordcloud && (
            <ListItemButton onClick={() => handleDownload('wordCloudTalk')}>
              <ListItemText primary="CSV - Talk WorldCloud" />
            </ListItemButton>
          )}
          {(page === 'community' || page === 'conversation') && (
            <>
              <ListItemButton
                onClick={() =>
                  handleDownload(
                    `topInfluencer${page[0].toUpperCase() + page.substring(1)}`
                  )
                }
              >
                <ListItemText primary="CSV - Top Influencer" />
              </ListItemButton>
              <ListItemButton
                onClick={() =>
                  handleDownload(
                    `topTalker${page[0].toUpperCase() + page.substring(1)}`
                  )
                }
              >
                <ListItemText primary="CSV - Top Talker" />
              </ListItemButton>
              <ListItemButton
                onClick={() =>
                  handleDownload(
                    `topEngagement${page[0].toUpperCase() + page.substring(1)}`
                  )
                }
              >
                <ListItemText primary="CSV - Top Talker by engagement" />
              </ListItemButton>
            </>
          )}
        </List>
      </Popover>
    </Stack>
  );
};

export default GroupObject;
